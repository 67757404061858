exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-admin-admin-dashboard-js": () => import("./../../../src/pages/admin/adminDashboard.js" /* webpackChunkName: "component---src-pages-admin-admin-dashboard-js" */),
  "component---src-pages-admin-editable-screen-js": () => import("./../../../src/pages/admin/editableScreen.js" /* webpackChunkName: "component---src-pages-admin-editable-screen-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-baby-care-profiles-js": () => import("./../../../src/pages/babyCareProfiles.js" /* webpackChunkName: "component---src-pages-baby-care-profiles-js" */),
  "component---src-pages-blog-dementia-care-js": () => import("./../../../src/pages/blog/dementia-care.js" /* webpackChunkName: "component---src-pages-blog-dementia-care-js" */),
  "component---src-pages-blog-home-health-care-js": () => import("./../../../src/pages/blog/home-health-care.js" /* webpackChunkName: "component---src-pages-blog-home-health-care-js" */),
  "component---src-pages-blog-homecare-nursingservices-js": () => import("./../../../src/pages/blog/homecare-nursingservices.js" /* webpackChunkName: "component---src-pages-blog-homecare-nursingservices-js" */),
  "component---src-pages-blog-intravenous-therapy-js": () => import("./../../../src/pages/blog/Intravenous-therapy.js" /* webpackChunkName: "component---src-pages-blog-intravenous-therapy-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-motherandbabycareservices-js": () => import("./../../../src/pages/blog/motherandbabycareservices.js" /* webpackChunkName: "component---src-pages-blog-motherandbabycareservices-js" */),
  "component---src-pages-blog-physical-therapy-js": () => import("./../../../src/pages/blog/physical-therapy.js" /* webpackChunkName: "component---src-pages-blog-physical-therapy-js" */),
  "component---src-pages-blog-role-caregivers-js": () => import("./../../../src/pages/blog/role-caregivers.js" /* webpackChunkName: "component---src-pages-blog-role-caregivers-js" */),
  "component---src-pages-blog-telehealth-homecare-js": () => import("./../../../src/pages/blog/telehealth-homecare.js" /* webpackChunkName: "component---src-pages-blog-telehealth-homecare-js" */),
  "component---src-pages-blog-urinary-catheterization-js": () => import("./../../../src/pages/blog/urinary-catheterization.js" /* webpackChunkName: "component---src-pages-blog-urinary-catheterization-js" */),
  "component---src-pages-blog-wound-dressing-js": () => import("./../../../src/pages/blog/Wound-dressing.js" /* webpackChunkName: "component---src-pages-blog-wound-dressing-js" */),
  "component---src-pages-book-care-js": () => import("./../../../src/pages/bookCare.js" /* webpackChunkName: "component---src-pages-book-care-js" */),
  "component---src-pages-caretaker-profile-js": () => import("./../../../src/pages/caretakerProfile.js" /* webpackChunkName: "component---src-pages-caretaker-profile-js" */),
  "component---src-pages-coverage-map-js": () => import("./../../../src/pages/CoverageMap.js" /* webpackChunkName: "component---src-pages-coverage-map-js" */),
  "component---src-pages-delete-account-js": () => import("./../../../src/pages/delete-account.js" /* webpackChunkName: "component---src-pages-delete-account-js" */),
  "component---src-pages-elderlycare-js": () => import("./../../../src/pages/elderlycare.js" /* webpackChunkName: "component---src-pages-elderlycare-js" */),
  "component---src-pages-enquire-now-js": () => import("./../../../src/pages/enquire-now.js" /* webpackChunkName: "component---src-pages-enquire-now-js" */),
  "component---src-pages-f-a-q-js": () => import("./../../../src/pages/f-a-q.js" /* webpackChunkName: "component---src-pages-f-a-q-js" */),
  "component---src-pages-followuptool-js": () => import("./../../../src/pages/followuptool.js" /* webpackChunkName: "component---src-pages-followuptool-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-hospital-services-js": () => import("./../../../src/pages/HospitalServices.js" /* webpackChunkName: "component---src-pages-hospital-services-js" */),
  "component---src-pages-how-to-book-care-js": () => import("./../../../src/pages/how-to-book-care.js" /* webpackChunkName: "component---src-pages-how-to-book-care-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-injectioninbangalore-js": () => import("./../../../src/pages/injectioninbangalore.js" /* webpackChunkName: "component---src-pages-injectioninbangalore-js" */),
  "component---src-pages-nurse-profiles-from-book-care-js": () => import("./../../../src/pages/nurseProfilesFromBookCare.js" /* webpackChunkName: "component---src-pages-nurse-profiles-from-book-care-js" */),
  "component---src-pages-nurse-registration-js": () => import("./../../../src/pages/nurse-registration.js" /* webpackChunkName: "component---src-pages-nurse-registration-js" */),
  "component---src-pages-nurseprofiles-js": () => import("./../../../src/pages/nurseprofiles.js" /* webpackChunkName: "component---src-pages-nurseprofiles-js" */),
  "component---src-pages-nurses-js": () => import("./../../../src/pages/nurses.js" /* webpackChunkName: "component---src-pages-nurses-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-profile-popup-form-js": () => import("./../../../src/pages/profilePopupForm.js" /* webpackChunkName: "component---src-pages-profile-popup-form-js" */),
  "component---src-pages-profiles-js": () => import("./../../../src/pages/profiles.js" /* webpackChunkName: "component---src-pages-profiles-js" */),
  "component---src-pages-referal-form-js": () => import("./../../../src/pages/ReferalForm.js" /* webpackChunkName: "component---src-pages-referal-form-js" */),
  "component---src-pages-referal-js": () => import("./../../../src/pages/Referal.js" /* webpackChunkName: "component---src-pages-referal-js" */),
  "component---src-pages-referal-success-js": () => import("./../../../src/pages/ReferalSuccess.js" /* webpackChunkName: "component---src-pages-referal-success-js" */),
  "component---src-pages-regionallocation-js": () => import("./../../../src/pages/regionallocation.js" /* webpackChunkName: "component---src-pages-regionallocation-js" */),
  "component---src-pages-salesdashboard-js": () => import("./../../../src/pages/salesdashboard.js" /* webpackChunkName: "component---src-pages-salesdashboard-js" */),
  "component---src-pages-services-cancercare-js": () => import("./../../../src/pages/services/cancercare.js" /* webpackChunkName: "component---src-pages-services-cancercare-js" */),
  "component---src-pages-services-criticalcare-js": () => import("./../../../src/pages/services/criticalcare.js" /* webpackChunkName: "component---src-pages-services-criticalcare-js" */),
  "component---src-pages-services-elderlycare-js": () => import("./../../../src/pages/services/elderlycare.js" /* webpackChunkName: "component---src-pages-services-elderlycare-js" */),
  "component---src-pages-services-injections-js": () => import("./../../../src/pages/services/Injections.js" /* webpackChunkName: "component---src-pages-services-injections-js" */),
  "component---src-pages-services-motherandbabycare-js": () => import("./../../../src/pages/services/motherandbabycare.js" /* webpackChunkName: "component---src-pages-services-motherandbabycare-js" */),
  "component---src-pages-services-nursing-js": () => import("./../../../src/pages/services/nursing.js" /* webpackChunkName: "component---src-pages-services-nursing-js" */),
  "component---src-pages-services-physiotherapy-1-js": () => import("./../../../src/pages/services/physiotherapy1.js" /* webpackChunkName: "component---src-pages-services-physiotherapy-1-js" */),
  "component---src-pages-services-physiotherapy-js": () => import("./../../../src/pages/services/physiotherapy.js" /* webpackChunkName: "component---src-pages-services-physiotherapy-js" */),
  "component---src-pages-services-post-operative-care-js": () => import("./../../../src/pages/services/post-operative-care.js" /* webpackChunkName: "component---src-pages-services-post-operative-care-js" */),
  "component---src-pages-services-postsurgerycare-js": () => import("./../../../src/pages/services/postsurgerycare.js" /* webpackChunkName: "component---src-pages-services-postsurgerycare-js" */),
  "component---src-pages-services-rtpcr-js": () => import("./../../../src/pages/services/rtpcr.js" /* webpackChunkName: "component---src-pages-services-rtpcr-js" */),
  "component---src-pages-services-servicestemplate-js": () => import("./../../../src/pages/services/Servicestemplate.js" /* webpackChunkName: "component---src-pages-services-servicestemplate-js" */),
  "component---src-pages-services-trained-attendants-js": () => import("./../../../src/pages/services/trained-attendants.js" /* webpackChunkName: "component---src-pages-services-trained-attendants-js" */),
  "component---src-pages-services-wound-care-js": () => import("./../../../src/pages/services/WoundCare.js" /* webpackChunkName: "component---src-pages-services-wound-care-js" */),
  "component---src-pages-staff-registration-js": () => import("./../../../src/pages/staff-registration.js" /* webpackChunkName: "component---src-pages-staff-registration-js" */),
  "component---src-pages-t-c-js": () => import("./../../../src/pages/t-c.js" /* webpackChunkName: "component---src-pages-t-c-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/ThankYou.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-trackermap-js": () => import("./../../../src/pages/trackermap.js" /* webpackChunkName: "component---src-pages-trackermap-js" */)
}

